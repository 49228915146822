<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!--      <bookmarks />-->
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <search-bar />-->
      <!--      <b-button
        variant="primary"
        to="/notifications"
        class="mr-1"
        size="sm"
      >
        <feather-icon icon="BellIcon" />
        Bildirimlerim
        <b-badge
          v-if="notifications"
          pill
          variant="warning"
          size="sm"
        >
          {{ notifications }}
        </b-badge>
      </b-button>-->
      <!--      <notification-dropdown />-->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton,
  // BNavItem,
  // BBadge,
  // BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar'
// import Bookmarks from '@core/layouts/components/app-navbar/components/Bookmarks'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import UserDropdown from '@/layouts/components/UserDropdown.vue'

export default {
  components: {
    // BNavItem,
    // NotificationDropdown,
    BLink,
    BNavbarNav,
    BButton,
    // BBadge,
    DarkToggler,
    UserDropdown,
    // SearchBar,
    // Bookmarks,
    // BNavItemDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BAvatar,
    // Navbar Components
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    // notifications() {
    //   return this.$store.getters['notifications/dataCount']
    // },
  },
  created() {
    // this.setNotifications()
  },
  methods: {
    // setNotifications() {
    //   this.$store.dispatch('notifications/getDataList', {
    //     where: {
    //       'com_notifications.is_read': null,
    //     },
    //     dataCount: true,
    //   })
    // },
  },
}
</script>
