export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Araç Ekspertiz',
    icon: 'MonitorIcon',
    children: [
      {
        title: 'Araç Sorgulama',
        route: 'CarSearch',
        resource: 'I2_PORTAL',
        icon: 'SearchIcon',
        action: 'read',
      },
      {
        title: 'Ekspertiz Araç Listesi',
        resource: 'I2_PORTAL',
        route: 'CarList',
        icon: 'ListIcon',
        action: 'read',
      },
    ],
  },
]
